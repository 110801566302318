import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LayoutComponent from "../LayoutComponent";
import { customFetch } from "../../utils/apiUtils";
import debounce from "lodash.debounce";

const Vendors = ({ setLoading }) => {
  const navigate = useNavigate();
  const [vendors, setVendors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [filterApplied, setFilterApplied] = useState(true);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const isAuthenticated = localStorage.getItem("authToken");

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [navigate, isAuthenticated]);

  const userDetails = JSON.parse(localStorage.getItem("user") || "[]");
  const selectedCompany = localStorage.getItem("selectedCompany");
  let hasWritePermission = false;
  if (isAuthenticated) {
    hasWritePermission = userDetails?.modules.some(
      (module: any) =>
        module.name.toString() === "Vendors" &&
        module.pivot.write.toString() === "1" &&
        module.pivot.company_id.toString() === selectedCompany.toString()
    );
  }

  const fetchVendors = useCallback(async () => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/vendors`;
    const params = {
      company_id: parseInt(selectedCompany),
      search_term: searchTerm,
      page: currentPage,
      per_page: 50,
    };
    const queryString = Object.keys(params)
      .map((key) => key + "=" + encodeURIComponent(params[key]))
      .join("&");
    const apiUrl = url + "?" + queryString;
    setLoading(true);
    try {
      const response = await customFetch(apiUrl, {
        method: "GET",
      });
      const data = await response.json();
      setVendors((prevItems) =>
        currentPage === 1 ? data.data : [...prevItems, ...data.data]
      );
      setCurrentPage(data.current_page);
      setHasMore(data.current_page < data.last_page);
      setFilterApplied(false);
    } catch (error) {
      console.error("Error fetching vendors:", error);
    } finally {
      setLoading(false);
    }
  }, [selectedCompany, searchTerm, currentPage, setLoading]);

  useEffect(() => {
    if (filterApplied) {
      fetchVendors();
    }
  }, [fetchVendors, filterApplied]);

  const handleSearch = debounce((event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
    setFilterApplied(true);
  }, 300);

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (!scrollContainerRef.current) return;
      const scrollPosition =
        scrollContainerRef.current.scrollTop +
        scrollContainerRef.current.clientHeight;
      const offsetHeight = scrollContainerRef.current.scrollHeight;
      if (scrollPosition >= offsetHeight - 10 && hasMore && !filterApplied) {
        setCurrentPage((prevPage) => prevPage + 1);
        setFilterApplied(true);
      }
    }, 300);

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
      return () => {
        scrollContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [hasMore, filterApplied]);

  const renderNoItemsMessage = () => {
    return (
      <Typography
        variant="h6"
        align="center"
        color="textSecondary"
        className="w-full !my-8"
      >
        No items found.
      </Typography>
    );
  };

  return (
    <LayoutComponent pageName="Vendors" setLoading={setLoading}>
      <Toolbar className="sticky top-0 z-10 border-b-2 pb-2">
        <TextField
          variant="outlined"
          placeholder="Search vendors..."
          className="flex-grow"
          onChange={handleSearch}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
        {hasWritePermission && (
          <IconButton
            aria-label="add"
            color="primary"
            onClick={() => navigate("/vendors/add")}
          >
            <AddIcon />
          </IconButton>
        )}
      </Toolbar>
      <Box ref={scrollContainerRef} className="overflow-auto p-4">
        <Grid container spacing={2}>
          {vendors.length === 0
            ? renderNoItemsMessage()
            : vendors.map((vendor) => (
                <Grid item xs={12} sm={6} md={4} key={vendor.id}>
                  <Card className="shadow-md">
                    <CardContent>
                      <Typography variant="h6" className="font-bold">
                        {vendor.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {vendor.address}
                      </Typography>
                    </CardContent>
                    <CardActions className="justify-end">
                      <IconButton
                        aria-label="view"
                        onClick={() => navigate(`/vendors/view/${vendor.id}`)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton
                        aria-label="edit"
                        onClick={() => navigate(`/vendors/edit/${vendor.id}`)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => console.log("Delete", vendor.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
        </Grid>
      </Box>
    </LayoutComponent>
  );
};

export default Vendors;
