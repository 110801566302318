import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainContent from "./MainContent";
import LayoutComponent from "../LayoutComponent";

const Dashboard = ({ setLoading }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("authToken");
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <LayoutComponent pageName="Dashboard" setLoading={setLoading}>
      <MainContent />
    </LayoutComponent>
  );
};

export default Dashboard;
