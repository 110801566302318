import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InventoryIcon from "@mui/icons-material/Inventory";
import StoreIcon from "@mui/icons-material/Store";
import CompanySwitcher from "./CompanySwitcher";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link } from "react-router-dom";

interface Module {
  id: number;
  name: string;
  submodules?: Module[];
}

interface SidebarComponentProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  desktopOpen: boolean;
  setDesktopOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: (isLoading: boolean) => void;
}

const SidebarComponent: React.FC<SidebarComponentProps> = ({
  mobileOpen,
  handleDrawerToggle,
  desktopOpen,
  setDesktopOpen,
  setLoading,
}) => {
  const [modules, setModules] = useState<Module[]>([]);
  const [openSubMenus, setOpenSubMenus] = useState<{ [key: number]: boolean }>(
    {}
  );

  useEffect(() => {
    const storedModules = localStorage.getItem("modules");
    if (storedModules) {
      setModules(JSON.parse(storedModules));
    }
  }, []);

  const handleToggleSubMenu = (id: number) => {
    setOpenSubMenus((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleLoadingChange = (isLoading) => {
    setLoading(isLoading);
  };

  const renderMenuItems = (modules: Module[]) => {
    return modules.map((module) => (
      <React.Fragment key={module.id}>
        <ListItem
          button
          onClick={() => module.submodules && handleToggleSubMenu(module.id)}
          component={Link}
          to={
            module.name === "Inventory"
              ? "/inventory"
              : module.name === "Vendors"
              ? "/vendors"
              : "/dashboard"
          }
        >
          <ListItemIcon>
            {module.name === "Dashboard" ? (
              <DashboardIcon />
            ) : module.name === "Inventory" ? (
              <InventoryIcon />
            ) : (
              <StoreIcon />
            )}
          </ListItemIcon>
          <ListItemText primary={module.name} />
          {module.submodules &&
            (openSubMenus[module.id] ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        {module.submodules && (
          <Collapse in={openSubMenus[module.id]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderMenuItems(module.submodules)}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    ));
  };

  const drawer = (
    <div>
      <CompanySwitcher
        setModules={setModules}
        setLoading={handleLoadingChange}
      />
      <Divider />
      <List>{renderMenuItems(modules)}</List>
    </div>
  );

  return (
    <nav className="flex-shrink-0">
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        className="lg:hidden"
        PaperProps={{ className: "mt-16 w-2/3 lg:w-3/12" }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        className={`w-2/12 ${desktopOpen ? "lg:block" : "hidden"}`}
        PaperProps={{ className: "mt-16 w-2/3 lg:w-3/12" }}
      >
        {drawer}
      </Drawer>
    </nav>
  );
};

export default SidebarComponent;
