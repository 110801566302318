import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import Loader from "./components/Loader";
import Inventory from "./pages/Inventory/Inventory";
import Vendors from "./pages/Vendors/Vendors";
import VendorForm from "./pages/Vendors/VendorForm";
import { ToastContainer } from "react-toastify";
import VendorDetails from "./pages/Vendors/VendorDetails";

const Root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const RootComponent = () => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <React.StrictMode>
        <Router>
          <Loader isLoading={loading} />
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/login" element={<Login setLoading={setLoading} />} />
            <Route
              path="/dashboard"
              element={<Dashboard setLoading={setLoading} />}
            />
            <Route
              path="/inventory"
              element={<Inventory setLoading={setLoading} />}
            />
            <Route
              path="/vendors"
              element={<Vendors setLoading={setLoading} />}
            />
            <Route
              path="/vendors/add"
              element={<VendorForm setLoading={setLoading} />}
            />
            <Route
              path="/vendors/edit/:id"
              element={<VendorForm setLoading={setLoading} />}
            />
            <Route
              path="/vendors/view/:id"
              element={<VendorDetails setLoading={setLoading} />}
            />
          </Routes>
          <ToastContainer />
        </Router>
      </React.StrictMode>
    </>
  );
};

Root.render(<RootComponent />);

reportWebVitals();
