import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { customFetch } from "../../utils/apiUtils";
import { darken, lighten, styled } from "@mui/material";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const FilterDrawer = ({
  open,
  onClose,
  showBelowThreshold,
  onThresholdChange,
  selectedCategories,
  onCategoryChange,
  onFilterChange,
  showZeroQuantity,
  onZeroQuantityChange,
  selectedMaterialType,
  onMaterialTypeChange,
}) => {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    if (open) {
      const selectedCompany = localStorage.getItem("selectedCompany");
      const fetchCategories = async () => {
        try {
          const response = await customFetch(
            `${process.env.REACT_APP_API_BASE_URL}/categories?company_id=${selectedCompany}`,
            {
              method: "GET",
            }
          );
          const data = await response.json();
          const modifiedCategories = data.map((category) => ({
            ...category,
            parent_name:
              category.parent_name === null
                ? category.name
                : category.parent_name,
          }));
          setCategories(modifiedCategories);
        } catch (error) {
          console.error("Error fetching categories:", error);
        }
      };
      fetchCategories();
    }
  }, [open]);

  const applyFilters = () => {
    console.log("selectedMaterialType", selectedMaterialType);
    onFilterChange({
      showBelowThreshold,
      selectedCategories,
      showZeroQuantity,
      selectedMaterialType,
    });
    onClose();
  };

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <div role="presentation" className="flex flex-col gap-4 p-4 pb-10">
        <div className="flex items-center justify-between gap-4">
          <FormControlLabel
            control={
              <Switch
                checked={showBelowThreshold}
                onChange={(e) => onThresholdChange(e.target.checked)}
              />
            }
            label="Below Min. Qty"
          />
          <FormControlLabel
            control={
              <Switch
                checked={showZeroQuantity}
                onChange={(e) => onZeroQuantityChange(e.target.checked)}
              />
            }
            label="Only Zero Qty"
          />
        </div>
        <Autocomplete
          multiple
          id="categories"
          options={categories.sort(
            (a, b) => -b.parent_name.localeCompare(a.parent_name)
          )}
          groupBy={(option) => option.parent_name}
          getOptionLabel={(option) => option.name}
          value={selectedCategories}
          onChange={(event, newValue) => onCategoryChange(newValue)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Filter by Categories"
            />
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option.name}
                {...getTagProps({ index })}
                key={option.id}
              />
            ))
          }
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader>{params.group}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
        />
        <Autocomplete
          id="material-type"
          options={[
            { label: "Raw Material", value: "raw_material" },
            { label: "Assembled", value: "assembled" },
            { label: "Final Product", value: "final_product" },
          ]}
          getOptionLabel={(option) => option.label}
          value={selectedMaterialType || null}
          onChange={(event, newValue) => {
            onMaterialTypeChange(newValue ? newValue.value : null);
          }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Material Type" />
          )}
        />
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={applyFilters}
          className="!mt-4"
        >
          Apply Filters
        </Button>
      </div>
    </Drawer>
  );
};

export default FilterDrawer;
