import React, { useState, useEffect } from "react";
import SplashScreen from "./pages/SplashScreen";
import Login from "./pages/Login";
import { useNavigate } from "react-router-dom";

const App: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      navigate("/login");
    }
  }, [isLoading, navigate]);

  return <>{isLoading ? <SplashScreen /> : <Login setLoading={false} />}</>;
};

export default App;
