import React from "react";
import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import UserMenu from "./UserMenu";

interface AppBarComponentProps {
  handleDrawerToggle: () => void;
  desktopOpen?: boolean;
  handleDesktopDrawerToggle?: () => void;
  pageName?: string;
}

const AppBarComponent: React.FC<AppBarComponentProps> = ({
  handleDrawerToggle,
  desktopOpen,
  handleDesktopDrawerToggle,
  pageName,
}) => {
  return (
    <AppBar position="fixed" className="!z-[1210]">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerToggle}
          className="mr-2 lg:!hidden"
        >
          <MenuIcon />
        </IconButton>
        {handleDesktopDrawerToggle && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="desktop-menu"
            onClick={handleDesktopDrawerToggle}
            className="!hidden lg:!inline-flex mr-2"
          >
            {desktopOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        )}
        <Typography variant="h6" noWrap className="flex-grow">
          {pageName}
        </Typography>
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
};

export default AppBarComponent;
