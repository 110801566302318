import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  Divider,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Typography,
  debounce,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { customFetch } from "../../utils/apiUtils";
import FilterDrawer from "./FilterDrawer";
import LayoutComponent from "../LayoutComponent";

interface Category {
  id: number;
  name: string;
}

interface InventoryItem {
  id: number;
  name: string;
  description: string;
  current_quantity: number;
  min_threshold: number;
  category: Category;
  breadcrumbs: [];
  type: string;
}

const Inventory = ({ setLoading }) => {
  const navigate = useNavigate();
  const [items, setItems] = useState<InventoryItem[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [showBelowThreshold, setShowBelowThreshold] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showZeroQuantity, setShowZeroQuantity] = useState(false);
  const [selectedMaterialType, setSelectedMaterialType] = useState(null);
  const [filterApplied, setFilterApplied] = useState(true);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const isAuthenticated = localStorage.getItem("authToken");
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [navigate, isAuthenticated]);

  const userDetails = JSON.parse(localStorage.getItem("user") || "[]");
  const selectedCompany = localStorage.getItem("selectedCompany");
  let hasWritePermission = false;
  if (isAuthenticated) {
    hasWritePermission = userDetails?.modules.some(
      (module: any) =>
        module.name.toString() === "Inventory" &&
        module.pivot.write.toString() === "1" &&
        module.pivot.company_id.toString() === selectedCompany.toString()
    );
  }

  const fetchItems = useCallback(async () => {
    const categoryIds = selectedCategories
      .map((category) => category.id)
      .join(",");
    const materialType = selectedMaterialType ? selectedMaterialType.value : "";
    const url = `${process.env.REACT_APP_API_BASE_URL}/items`;
    const params = {
      company_id: parseInt(selectedCompany),
      categories: categoryIds,
      search_term: searchTerm,
      below_threshold: showBelowThreshold,
      zero_quantity: showZeroQuantity,
      material_type: materialType,
      page: currentPage,
      per_page: 50,
    };
    const queryString = Object.keys(params)
      .map((key) => key + "=" + encodeURIComponent(params[key]))
      .join("&");
    const apiUrl = url + "?" + queryString;
    setLoading(true);
    try {
      const response = await customFetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setItems((prevItems) =>
        currentPage === 1 ? data.data : [...prevItems, ...data.data]
      );
      setCurrentPage(data.current_page);
      setHasMore(data.current_page < data.last_page);
      setLoading(false);
      setFilterApplied(false);
    } catch (error) {
      console.error("Error fetching inventory items:", error);
      setLoading(false);
    }
  }, [
    selectedCompany,
    searchTerm,
    selectedCategories,
    showBelowThreshold,
    showZeroQuantity,
    selectedMaterialType,
    currentPage,
    setLoading,
  ]);

  useEffect(() => {
    if (filterApplied) {
      fetchItems();
    }
  }, [fetchItems, filterApplied]);

  const toggleFilterDrawer = (open) => () => {
    setFilterDrawerOpen(open);
  };

  const handleSearch = debounce((event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
    setFilterApplied(true);
  }, 300);

  const handleFilterChange = (criteria) => {
    setShowBelowThreshold(criteria.showBelowThreshold);
    setSelectedCategories(criteria.selectedCategories);
    setShowZeroQuantity(criteria.showZeroQuantity);
    setSelectedMaterialType(criteria.selectedMaterialType || null);
    setCurrentPage(1);
    setItems([]);
    setFilterApplied(true);
  };

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (!scrollContainerRef.current) return;
      const scrollPosition =
        scrollContainerRef.current.scrollTop +
        scrollContainerRef.current.clientHeight;
      const offsetHeight = scrollContainerRef.current.scrollHeight;
      if (scrollPosition >= offsetHeight - 10 && hasMore) {
        setCurrentPage((prevPage) => prevPage + 1);
        setFilterApplied(true);
      }
    }, 300);

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
      return () => {
        scrollContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [hasMore]);

  const renderNoItemsMessage = () => {
    return (
      <Typography
        variant="h6"
        align="center"
        color="textSecondary"
        className="w-full !my-8"
      >
        No items found.
      </Typography>
    );
  };

  return (
    <LayoutComponent pageName="Inventory" setLoading={setLoading}>
      <Toolbar className="sticky top-0 z-10 border-b-2 pb-2">
        <TextField
          variant="outlined"
          placeholder="Search items..."
          className="flex-grow"
          onChange={handleSearch}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
            // disabled: items.length === 0,
          }}
        />
        <IconButton
          onClick={toggleFilterDrawer(true)}
          // disabled={items.length === 0}
        >
          <FilterListIcon />
        </IconButton>
        {hasWritePermission && (
          <IconButton aria-label="add" color="primary">
            <AddIcon />
          </IconButton>
        )}
      </Toolbar>
      <Box ref={scrollContainerRef} className="overflow-auto p-4 md:p-8">
        <Grid container spacing={2}>
          {items.length === 0
            ? renderNoItemsMessage()
            : items.map((item: InventoryItem, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={item.id + "" + index}
                >
                  <Card
                    className={`flex flex-row justify-between content-center p-4 !shadow-md border-2 border-transparent ${
                      item.current_quantity < item.min_threshold
                        ? // ? "!border-red-200 !shadow-red-500/50"
                          "!bg-red-50"
                        : "!bg-blue-50"
                    }`}
                    style={{
                      backgroundImage: `url(${
                        item.type === "raw_material"
                          ? "/images/puzzle-piece.png"
                          : "/images/assembled.png"
                      })`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "70% center",
                      backgroundSize: "75px",
                      backgroundBlendMode: "overlay",
                    }}
                  >
                    <Box className="basis-9/12">
                      <Typography className="!text-base !font-bold">
                        {item.name}
                      </Typography>
                      <Typography className="!text-xs !text-gray-400 !mt-1">
                        {item.description}
                      </Typography>
                      <Divider className="!my-2 w-2/3" />
                      {/* <Typography
                          color={"primary"}
                          className="!text-xs flex flex-row items-center"
                        > */}
                      <Box className="flex flex-row items-center">
                        {item.breadcrumbs.map((breadcrumb, index) => (
                          <React.Fragment key={index}>
                            <Typography color={"primary"} className="!text-xs">
                              {breadcrumb}
                              {index !== item.breadcrumbs.length - 1 && (
                                <span>&nbsp;/&nbsp;</span>
                              )}
                            </Typography>
                          </React.Fragment>
                        ))}
                      </Box>
                      {/* </Typography> */}
                    </Box>
                    <Box className="basis-3/12 content-center text-center">
                      <Typography
                        color={
                          item.current_quantity < item.min_threshold
                            ? "error"
                            : "primary"
                        }
                        className="!text-lg !font-bold"
                      >
                        {item.current_quantity}
                        {item.current_quantity < item.min_threshold && (
                          <span className="block text-xs">
                            (Min: {item.min_threshold})
                          </span>
                        )}
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
              ))}
        </Grid>
      </Box>
      <FilterDrawer
        open={filterDrawerOpen}
        onClose={toggleFilterDrawer(false)}
        showBelowThreshold={showBelowThreshold}
        onThresholdChange={setShowBelowThreshold}
        selectedCategories={selectedCategories}
        onCategoryChange={setSelectedCategories}
        onFilterChange={handleFilterChange}
        showZeroQuantity={showZeroQuantity}
        onZeroQuantityChange={setShowZeroQuantity}
        selectedMaterialType={selectedMaterialType}
        onMaterialTypeChange={setSelectedMaterialType}
      />
    </LayoutComponent>
  );
};

export default Inventory;
