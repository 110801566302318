import React, { useState } from "react";
import AppBarComponent from "../components/AppBarComponent";
import SidebarComponent from "../components/SidebarComponent";
import { Box } from "@mui/material";

const LayoutComponent = ({ children, pageName, setLoading }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [desktopOpen, setDesktopOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    if (window.innerWidth >= 1024) {
      setDesktopOpen(!desktopOpen);
    }
  };

  const handleDesktopDrawerToggle = () => {
    setDesktopOpen(!desktopOpen);
  };

  const handleLoadingChange = (isLoading) => {
    setLoading(isLoading);
  };

  return (
    <div className="flex h-screen bg-gray-50">
      <AppBarComponent
        handleDrawerToggle={handleDrawerToggle}
        desktopOpen={desktopOpen}
        handleDesktopDrawerToggle={handleDesktopDrawerToggle}
        pageName={pageName}
      />
      <SidebarComponent
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        desktopOpen={desktopOpen}
        setDesktopOpen={setDesktopOpen}
        setLoading={handleLoadingChange}
      />
      <Box className="flex flex-col mt-9 pt-9 w-full">{children}</Box>
    </div>
  );
};

export default LayoutComponent;
