import React, {
  useState,
  useEffect,
  ChangeEvent,
  FormEvent,
  useCallback,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  Toolbar,
  IconButton,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LayoutComponent from "../LayoutComponent";
import { customFetch } from "../../utils/apiUtils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Vendor {
  name: string;
  contact_person: string;
  contact_email: string;
  phone: string;
  address: string;
  gst_number?: string;
  company_id?: string | null;
}

const VendorForm = ({
  setLoading,
}: {
  setLoading: (isLoading: boolean) => void;
}) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [vendor, setVendor] = useState<Vendor>({
    name: "",
    contact_person: "",
    contact_email: "",
    phone: "",
    address: "",
    gst_number: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const selectedCompany = localStorage.getItem("selectedCompany");

  const fetchVendorDetails = useCallback(async () => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/vendors/${id}`;
    setLoading(true);
    try {
      const response = await customFetch(url, { method: "GET" });
      const data = await response.json();
      setVendor(data);
    } catch (error) {
      console.error("Error fetching vendor details:", error);
      toast.error("Error fetching vendor details");
    } finally {
      setLoading(false);
    }
  }, [id, setLoading]);

  useEffect(() => {
    if (id) {
      setIsEdit(true);
      fetchVendorDetails();
    }
  }, [id, fetchVendorDetails]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setVendor((prevVendor) => ({
      ...prevVendor,
      [name]: value,
    }));
  };

  const validateForm = () => {
    if (
      !vendor.name ||
      !vendor.contact_person ||
      !vendor.contact_email ||
      !vendor.phone ||
      !vendor.address ||
      !vendor.gst_number
    ) {
      toast.error("All fields are required");
      return false;
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(vendor.contact_email)) {
      toast.error("Invalid email format");
      return false;
    }
    const phonePattern = /^[0-9]{10}$/;
    if (!phonePattern.test(vendor.phone)) {
      toast.error("Phone number must be 10 digits");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    const url = isEdit
      ? `${process.env.REACT_APP_API_BASE_URL}/vendors/${id}`
      : `${process.env.REACT_APP_API_BASE_URL}/vendors`;
    const method = isEdit ? "PUT" : "POST";
    const payload = { ...vendor, company_id: selectedCompany };
    setLoading(true);
    try {
      await customFetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      toast.success(`Vendor ${isEdit ? "updated" : "added"} successfully`);
      navigate("/vendors");
    } catch (error) {
      console.error("Error saving vendor:", error);
      toast.error("Error saving vendor");
    } finally {
      setLoading(false);
    }
  };

  return (
    <LayoutComponent
      pageName={isEdit ? "Edit Vendor" : "Add Vendor"}
      setLoading={setLoading}
    >
      <Toolbar className="sticky top-0 z-10 border-b-2 pb-4">
        <IconButton onClick={() => navigate("/vendors")}>
          <ArrowBackIcon />
        </IconButton>
      </Toolbar>
      <Box className="flex justify-center p-4">
        <Card className="w-full max-w-lg shadow-md">
          <CardContent>
            <Typography variant="h5" className="mb-4">
              {isEdit ? "Edit Vendor" : "Add Vendor"}
            </Typography>
            <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
              <TextField
                label="Name"
                variant="outlined"
                name="name"
                value={vendor.name}
                onChange={handleChange}
                fullWidth
                required
              />
              <TextField
                label="Contact Person"
                variant="outlined"
                name="contact_person"
                value={vendor.contact_person}
                onChange={handleChange}
                fullWidth
                required
              />
              <TextField
                label="Contact Email"
                variant="outlined"
                name="contact_email"
                type="email"
                value={vendor.contact_email}
                onChange={handleChange}
                fullWidth
                required
              />
              <TextField
                label="Phone"
                variant="outlined"
                name="phone"
                value={vendor.phone}
                onChange={handleChange}
                fullWidth
                required
              />
              <TextField
                label="Address"
                variant="outlined"
                name="address"
                value={vendor.address}
                onChange={handleChange}
                fullWidth
                required
              />
              <TextField
                label="GST Number"
                variant="outlined"
                name="gst_number"
                value={vendor.gst_number}
                onChange={handleChange}
                fullWidth
                required
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                fullWidth
              >
                {isEdit ? "Update Vendor" : "Add Vendor"}
              </Button>
            </form>
          </CardContent>
        </Card>
      </Box>
    </LayoutComponent>
  );
};

export default VendorForm;
