import React from "react";
import { Toolbar } from "@mui/material";

const MainContent = () => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  return (
    <main className="flex-grow p-3">
      <Toolbar />
      <section className="flex items-center justify-center">
        <div className="text-2xl font-semibold text-gray-900">
          Welcome, {user.name || "U"}
        </div>
      </section>
    </main>
  );
};

export default MainContent;
