import React from "react";

const SplashScreen: React.FC = () => {
  return (
    <div
      className="flex justify-center items-center h-screen bg-repeat bg-white/80 bg-blend-overlay"
      style={{ backgroundImage: 'url("/images/splashBg.jpg")' }}
    >
      <h1 className="w-screen text-5xl leading-normal font-bold text-center py-5">
        SN Industry Management
      </h1>
    </div>
  );
};

export default SplashScreen;
