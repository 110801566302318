import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Login = ({ setLoading }) => {
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<{ mobile?: string; password?: string }>(
    {}
  );
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("authToken");
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const validate = () => {
    const newErrors: { mobile?: string; password?: string } = {};

    if (!mobile) {
      newErrors.mobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(mobile)) {
      newErrors.mobile = "Mobile number must be 10 digits";
    }

    if (!password) {
      newErrors.password = "Password is required";
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/login`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({ mobile, password }),
          }
        );
        const data = await response.json();
        if (response.ok) {
          localStorage.setItem("authToken", data.token);
          localStorage.setItem("user", JSON.stringify(data.employee));
          const companyResponse = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/user/companies`,
            {
              headers: {
                Authorization: `Bearer ${data.token}`,
              },
            }
          );
          if (companyResponse.ok) {
            const companyData = await companyResponse.json();
            localStorage.setItem(
              "companies",
              JSON.stringify(companyData.companies)
            );
            localStorage.setItem("defaultCompany", companyData.default_company);
            localStorage.setItem(
              "selectedCompany",
              companyData.default_company
            );
          }
          navigate("/dashboard");
        } else {
          setMessage(data.message || "An error occurred. Please try again.");
        }
      } catch (error) {
        console.error("Error:", error);
        setMessage("An error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <section
      className="h-screen flex flex-col items-center justify-center mx-auto px-6 bg-repeat bg-white/80 bg-blend-overlay"
      style={{ backgroundImage: 'url("/images/loginBg.jpg")' }}
    >
      <div className="mb-6 text-2xl font-semibold text-gray-900">
        <img className="w-auto h-auto" src="images/SN_logo.png" alt="logo" />
      </div>
      <div className="w-full bg-white rounded-lg shadow-2xl md:mt-0 sm:max-w-md xl:p-0">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
            <div className="flex flex-col">
              <label
                htmlFor="mobile"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Mobile Number
              </label>
              <input
                type="tel"
                name="mobile"
                id="mobile"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                className={`bg-gray-50 border ${
                  errors.mobile ? "border-red-500" : "border-gray-300"
                } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                placeholder="Enter your mobile number"
              />
              {errors.mobile && (
                <p className="text-red-500 text-xs mt-1">{errors.mobile}</p>
              )}
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={`bg-gray-50 border ${
                  errors.password ? "border-red-500" : "border-gray-300"
                } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                placeholder="Enter your password"
              />
              {errors.password && (
                <p className="text-red-500 text-xs mt-1">{errors.password}</p>
              )}
            </div>
            <button
              type="submit"
              className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Sign in
            </button>
          </form>
          {message && (
            <p className="text-center mt-4 text-sm text-red-500">{message}</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Login;
