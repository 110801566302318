import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Toolbar,
  IconButton,
  CardHeader,
  CardActions,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LayoutComponent from "../LayoutComponent";
import { customFetch } from "../../utils/apiUtils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ShoppingCart, ArrowUpward, CheckCircle } from "@mui/icons-material";

const VendorDetails = ({
  setLoading,
}: {
  setLoading: (isLoading: boolean) => void;
}) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [vendor, setVendor] = useState<any>(null);
  const [items, setItems] = useState<any[]>([]);
  const [purchaseHistory, setPurchaseHistory] = useState<any[]>([]);

  const fetchVendorDetails = useCallback(async () => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/vendors/${id}`;
    setLoading(true);
    try {
      const response = await customFetch(url, { method: "GET" });
      const data = await response.json();
      setVendor(data);
    } catch (error) {
      console.error("Error fetching vendor details:", error);
      toast.error("Error fetching vendor details");
    } finally {
      setLoading(false);
    }
  }, [id, setLoading]);

  const fetchItems = useCallback(async () => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/vendors/${id}/items`;
    setLoading(true);
    try {
      const response = await customFetch(url, { method: "GET" });
      const data = await response.json();
      setItems(data);
    } catch (error) {
      console.error("Error fetching items:", error);
      toast.error("Error fetching items");
    } finally {
      setLoading(false);
    }
  }, [id, setLoading]);

  /* const fetchPurchaseHistory = useCallback(async () => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/vendors/${id}/purchase-history`;
    setLoading(true);
    try {
      const response = await customFetch(url, { method: "GET" });
      const data = await response.json();
      setPurchaseHistory(data);
    } catch (error) {
      console.error("Error fetching purchase history:", error);
      toast.error("Error fetching purchase history");
    } finally {
      setLoading(false);
    }
  }, [id, setLoading]); */

  const fetchPurchaseHistory = useCallback(async () => {
    const dummyData = [
      {
        id: 1,
        poNumber: "PO001",
        finalPOValue: 8000,
        poDate: "September 14, 2024",
        items: [
          {
            name: "Item 1",
            poRaisedQty: 10,
            receivedQty: 8,
          },
          {
            name: "Item 2",
            poRaisedQty: 20,
            receivedQty: 20,
          },
        ],
      },
      {
        id: 2,
        poNumber: "PO002",
        finalPOValue: 6000,
        poDate: "September 24, 2024",
        items: [
          {
            name: "Item 3",
            poRaisedQty: 15,
            receivedQty: 15,
          },
          {
            name: "Item 4",
            poRaisedQty: 12,
            receivedQty: 10,
          },
        ],
      },
    ];

    setLoading(true);
    try {
      // Simulate a delay
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setPurchaseHistory(dummyData);
    } catch (error) {
      console.error("Error fetching purchase history:", error);
      toast.error("Error fetching purchase history");
    } finally {
      setLoading(false);
    }
  }, [setLoading]);

  useEffect(() => {
    if (id) {
      fetchVendorDetails();
      fetchItems();
      fetchPurchaseHistory();
    }
  }, [id, fetchVendorDetails, fetchItems, fetchPurchaseHistory]);

  return (
    <LayoutComponent pageName="Vendor Details" setLoading={setLoading}>
      <Toolbar className="sticky top-0 z-10 border-b-2 pb-2 !min-h-10">
        <IconButton onClick={() => navigate("/vendors")}>
          <ArrowBackIcon />
        </IconButton>
      </Toolbar>
      <Box className="p-4">
        <Typography variant="h5" className="!mb-4">
          Vendor Details
        </Typography>
        {vendor ? (
          <Card className="mb-4 shadow-md">
            <CardContent>
              <Typography variant="body1">
                <strong>Name:</strong> {vendor.name}
              </Typography>
              <Typography variant="body1">
                <strong>Contact Person:</strong> {vendor.contact_person}
              </Typography>
              <Typography variant="body1">
                <strong>Contact Email:</strong> {vendor.contact_email}
              </Typography>
              <Typography variant="body1">
                <strong>Phone:</strong> {vendor.phone}
              </Typography>
              <Typography variant="body1">
                <strong>Address:</strong> {vendor.address}
              </Typography>
            </CardContent>
          </Card>
        ) : (
          <Typography
            variant="h6"
            align="center"
            color="textSecondary"
            className="my-8"
          >
            Loading vendor details...
          </Typography>
        )}
        <Box className="mb-4">
          <Typography variant="h5" className="!mb-4">
            Item Details
          </Typography>
          <Grid container spacing={2}>
            {items.length > 0 ? (
              items.map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item.id}>
                  <Card className="shadow-md">
                    <CardContent>
                      <Typography variant="h6" className="font-bold">
                        {item.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {item.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography variant="body1" color="textSecondary">
                No items associated with this vendor.
              </Typography>
            )}
          </Grid>
        </Box>
        <Box>
          <Typography variant="h5" className="!mb-4">
            History
          </Typography>
          <Grid container spacing={2}>
            {purchaseHistory.length > 0 ? (
              purchaseHistory.map((purchase) => (
                <Grid item xs={12} sm={6} md={3} key={purchase.id}>
                  <Card className="shadow-md">
                    <CardHeader
                      title={purchase.poNumber}
                      subheader={purchase.poDate}
                    />
                    <CardContent>
                      {purchase.items.map((item) => (
                        <>
                          <div
                            key={item.name}
                            className="flex items-center mb-4"
                          >
                            <Typography variant="body2" color="textSecondary">
                              <ShoppingCart fontSize="small" /> {item.name}{" "}
                              &nbsp;
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <ArrowUpward fontSize="small" />{" "}
                              {item.poRaisedQty} &nbsp;
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <CheckCircle fontSize="small" />{" "}
                              {item.receivedQty}
                            </Typography>
                          </div>
                        </>
                      ))}
                    </CardContent>
                    <CardActions>
                      <Typography variant="h6">
                        &#x20b9;&nbsp;{purchase.finalPOValue}
                      </Typography>
                    </CardActions>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography variant="body1" color="textSecondary">
                No purchase history available.
              </Typography>
            )}
          </Grid>
        </Box>
      </Box>
    </LayoutComponent>
  );
};

export default VendorDetails;
