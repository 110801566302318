import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import { customFetch } from "../utils/apiUtils";

type Company = {
  id: string;
  name: string;
  logo: string;
};

interface CompanySwitcherProps {
  setModules: (modules: any) => void;
  setLoading: (isLoading: boolean) => void;
}

const CompanySwitcher: React.FC<CompanySwitcherProps> = ({
  setModules,
  setLoading,
}) => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);

  useEffect(() => {
    const storedCompanies = localStorage.getItem("companies");
    const storedSelectedCompany = localStorage.getItem("selectedCompany");

    if (storedCompanies) {
      setCompanies(JSON.parse(storedCompanies));
      if (storedSelectedCompany) {
        const defaultCompanyId = JSON.parse(storedSelectedCompany);
        const selected = JSON.parse(storedCompanies).find(
          (company: Company) => company.id === defaultCompanyId
        );
        setSelectedCompany(selected || JSON.parse(storedCompanies)[0]);
        fetchModulesForCompany(defaultCompanyId);
      }
    }
  }, []);

  const fetchModulesForCompany = async (companyId) => {
    setLoading(true);
    setError(null);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await customFetch(
        `${process.env.REACT_APP_API_BASE_URL}/user/${companyId}/modules`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.ok) {
        const modules = await response.json();
        localStorage.setItem("modules", JSON.stringify(modules?.modules));
        setModules(modules?.modules);
      } else {
        setError("Error fetching modules");
      }
    } catch (error) {
      console.error("Error fetching modules:", error);
      setError("Error fetching modules");
    } finally {
      setLoading(false);
    }
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCompanySelect = (company: Company) => {
    setSelectedCompany(company);
    localStorage.setItem("selectedCompany", JSON.stringify(company.id));
    fetchModulesForCompany(company.id);
    handleClose();
  };

  return (
    <List>
      <ListItem button onClick={handleMenu}>
        <ListItemIcon>
          <Avatar src={selectedCompany?.logo} />
        </ListItemIcon>
        <ListItemText primary={selectedCompany?.name || "Select Company"} />
      </ListItem>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {error ? (
          <MenuItem disabled>Error: {error}</MenuItem>
        ) : (
          companies.map((company) => (
            <MenuItem
              key={company.id}
              onClick={() => handleCompanySelect(company)}
            >
              <Avatar src={company.logo} />
              {company.name}
            </MenuItem>
          ))
        )}
      </Menu>
    </List>
  );
};

export default CompanySwitcher;
