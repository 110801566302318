import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = ({ isLoading }) => {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 bg-black opacity-50 bg-blend-overlay ${
        isLoading ? "block" : "hidden"
      }`}
    >
      <CircularProgress />
    </div>
  );
};

export default Loader;
