const refreshToken = async () => {
  const currentToken = localStorage.getItem("authToken");
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/refresh-token`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    }
  );

  if (response.ok) {
    const data = await response.json();
    localStorage.setItem("authToken", data.newToken);
    return data.newToken;
  } else {
    throw new Error("Unable to refresh token");
  }
};

const customFetch = async (url: string, options: RequestInit = {}) => {
  const authToken = localStorage.getItem("authToken");
  options.headers = {
    ...options.headers,
    Authorization: `Bearer ${authToken}`,
  };

  const response = await fetch(url, options);

  if (response.status === 401) {
    try {
      const newToken = await refreshToken();
      options.headers = {
        ...options.headers,
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      };
      return fetch(url, options);
    } catch (error) {
      localStorage.clear();
      window.location.href = "/login";
    }
  } else {
    return response;
  }
};

export { customFetch, refreshToken };
